export const productCategories = [
  {
    key: "avtalstjanster",
    title: "Avtalstjänster",
    title_en: "Call-off contracts",
    icon: "doc",
    subCategories: [
      {
        key: "avropa-avtalstjanster",
        enabled: true,
        enabledInTeliaAdmin: false,
        title: "Avrop på avtalstjänster",
        title_en: "Call off contracts",
        description: "Gör avrop på avtalstjänster via Service Desk Web.",
        description_en: "Call off services from a framework agreement through Service Desk Web.",
        icon: "doc",
        pui: "SDW",
        link: {
          url:
            "https://servicedeskweb.telia.se/SSO/MyBusinessLogin.aspx?ReturnUrl=/Order/NewMultiOrder.aspx",
          target: "_blank",
        },
      },
      {
        key: "avropa-avtalstjanster-ej-inloggad",
        enabled: true,
        enabledInTeliaAdmin: false,
        title: "Avrop på avtalstjänster",
        title_en: "Call off contracts",
        description: "Logga in till Service Desk Web för att göra avrop på era avtalstjänster.",
        description_en:
          "Log in to call off services from a framework agreement through Service Desk Web.",
        icon: "login",
        pui: "SDW_NO_SSO",
        link: {
          url: "https://servicedeskweb.telia.se/SSO/MyBusinessLogin.aspx",
          target: "_blank",
        },
      },
    ],
  },
  {
    key: "mobil-telefoni",
    title: "Mobiltelefoni",
    title_en: "Mobile Telephony",
    icon: "smartphone",
    subCategories: [
      {
        key: "mobiltelefoner",
        enabled: false,
        enabledInTeliaAdmin: false,
        title: "Mobiltelefoner",
        title_en: "Mobile Phones",
        description:
          "Skaffa de bästa mobila lösningarna för företaget här. Inloggning krävs för ert företags unika Webbshop.",
        description_en: "Get the best mobile solutions for your company.",
        icon: "smartphone",
        pui: "TRADE",
        link: {
          url:
            "https://trade.telia.se/foretag/handla/search?q=Mobiltelefoner#?catid=128&filters=%255B%255D",
          target: "_blank",
        },
      },
      {
        key: "mobiltelefoner-mco",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Mobiltelefoner",
        title_en: "Mobile Phones",
        description: "Skaffa de bästa mobila lösningarna för företaget här.",
        description_en: "Get the best mobile solutions for your company.",
        icon: "smartphone",
        pui: "",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/:tscid/mobiltelefoner",
          target: "",
        },
      },
      {
        key: "mobilabonnemang",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Mobilabonnemang",
        title_en: "Mobile Subscriptions",
        description: "Skaffa de bästa mobila lösningarna för företaget här.",
        description_en: "Get the best mobile solutions for your company.",
        icon: "simcard",
        pui: "CREATE_ORDER",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/oppna-mobilt-abonnemang",
          target: "",
        },
      },
      {
        key: "mobilabonnemang-mco",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Mobilabonnemang",
        title_en: "Mobile Subscriptions",
        description: "Skaffa de bästa mobila lösningarna för företaget här.",
        description_en: "Get the best mobile solutions for your company.",
        icon: "simcard",
        pui: "CREATE_ORDER",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/:tscid/mobilabonnemang",
          target: "",
        },
      },
    ],
  },
  {
    key: "bredband",
    title: "Bredband",
    title_en: "Broadband",
    icon: "home",
    subCategories: [
      {
        key: "bredband-otp",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Bredband",
        title_en: "Broadband",
        description: "Skaffa de bästa bredbandslösningarna för företaget här.",
        description_en: "Get the best broadband solutions for your company.",
        icon: "home",
        pui: "ORDER_INTERNET_ACCESS",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/:tscid/bredband",
          fallbackUrl: "/foretag/bredband/bestall",
          target: "",
        },
      },
    ],
  },
  {
    key: "formaner",
    title: "Förmåner",
    title_en: "Benefits",
    icon: "present",
    subCategories: [
      {
        key: "formaner",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Telia Pluskund",
        title_en: "Telia Business Plus",
        description: "Här kan du som är Telia Pluskund hantera dina förmåner.",
        description_en: "Benefits for your organisation.",
        icon: "present",
        pui: "ORDER_BENEFITS",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/formaner",
          target: "",
        },
      },
    ],
  },
  {
    key: "fast-telefoni",
    title: "Fast telefoni",
    title_en: "Fixed telephony",
    icon: "phone",
    subCategories: [
      {
        key: "abonnemang",
        enabled: false,
        enabledInTeliaAdmin: false,
        title: "Abonnemang",
        title_en: "Subscription",
        description: "Fasta abonnemang för företagets telefon.",
        description_en: "Fixed telephony subscriptions.",
        icon: "phone-ringing",
        pui: "READ_FIXED",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/produkter-tjanster/fast-telefoni/abonnemang",
          target: "",
        },
      },
    ],
  },
  {
    key: "kommunikationstjanster",
    title: "Kommunikationstjänster",
    title_en: "Communication Services",
    icon: "cloud",
    subCategories: [
      {
        key: "motestjanster",
        enabled: false,
        enabledInTeliaAdmin: false,
        title: "Mötestjänster",
        title_en: "Meeting Services",
        description:
          "Med mötestjänster kan ditt företag träffa kollegor och kunder utan att behöva resa. För dig som är avtalskund, var vänlig beställ via din ordinarie kundingång.",
        description_en:
          "Meet colleagues and customers without travelling through meeting services.",
        icon: "video-conference",
        pui: "READ_SERVICE",
        link: {
          url:
            "/foretag/mybusiness/:scopeId/bestall/produkter-tjanster/kommunikationstjanster/motestjanster",
          target: "",
        },
      },
      {
        key: "IoT-subscriptions",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "IoT (M2M)",
        title_en: "IoT (M2M)",
        description:
          "För uppkoppling av dina enheter mot internet eller privat datanät. Exempelvis sensorer, fjärravläsning, larm- och övervakningslösningar.",
        description_en:
          "For connecting your devices to the internet or private data network. For example, sensors, remote reading, alarm, and surveillance solutions.",
        icon: "smart-wifi",
        pui: "CREATE_ORDER",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/oppna-iot-abonnemang",
          target: "",
        },
      },
      {
        key: "iot-sim",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "IoT (M2M) SIM-kort",
        title_en: "IoT (M2M) SIM cards",
        description: "Beställ nya IoT (M2M) SIM-kort. Levereras i paket om 100 eller 500 SIM-kort.",
        description_en:
          "Order new IoT (M2M) SIM cards. Delivered in packages of 100 or 500 SIM cards.",
        icon: "simcard",
        pui: "CREATE_ORDER",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/iot-sim-kort",
          target: "",
        },
      },
      {
        key: "emn-subscriptions",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "EMN accesser med prioritet",
        title_en: "EMN access with priority",
        description:
          "Säkra hög prioritet och tillgänglighet för de viktigaste enheterna i ert dedikerade nät. Prioritets-simkorten kan användas för kritisk kommunikation i maskiner, lastbilar, videoutrustning och andra viktiga sensorer till exempel.",
        description_en:
          "Ensure high priority and availability for your most important devices on your dedicated network. Priority SIM cards can be used for critical communication in machinery, trucks, video equipment, and other vital sensors, for example.",
        icon: "connected-building",
        pui: "CREATE_ORDER",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/:tscid/emn",
          target: "",
        },
      },
    ],
  },
  {
    key: "vaxlar",
    title: "Unified Communication och Växel",
    title_en: "Unified Communication and Switches",
    icon: "network",
    subCategories: [
      {
        key: "touchpoint-plus-new-order",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Ny Touchpoint Plus-kund",
        title_en: "New Touchpoint Plus customer",
        description: "Registrera användardata för er nya Touchpoint Plus-lösning.",
        description_en: "Register user data for your new Touchpoint Plus solution.",
        icon: "switch",
        pui: "ORDER_TOUCHPOINT_PLUS",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/touchpoint-plus-nybestallning",
          target: "",
        },
      },
      {
        key: "touchpoint-plus-aftermarket",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Touchpoint Plus eftermarknad",
        title_en: "Touchpoint Plus aftermarket",
        description: "Gör ändringar i er befintliga Touchpoint Plus-lösning.",
        description_en: "Make changes to your existing Touchpoint Plus solution.",
        icon: "switch",
        pui: "ORDER_TOUCHPOINT_PLUS",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/touchpoint-plus-eftermarknad",
          target: "",
        },
      },
      {
        key: "office-extension",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Office Extension, Dirigent Kontroll, Telia Mobil Integration",
        title_en: "Office Extension, Dirigent Kontroll, Telia Mobil Integration",
        description: "Beställ inkoppling av mobil anknytning.",
        description_en: "Order connection of mobile extension.",
        icon: "smartphone",
        pui: "READ_SWITCH",
        link: {
          url: "//casey-ext.han.telia.se/600",
          target: "_blank",
        },
      },
      {
        key: "telia-ace",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Telia ACE",
        title_en: "Telia ACE",
        description:
          "Telia ACE är en kontaktcenterlösning för sömlösa kundmöten och smarta konversationer.",
        description_en:
          "Telia ACE is a customer service solution designed for seamless customer meetings and smart conversations.",
        icon: "support",
        pui: "",
        link: {
          url: "//casey-ext.han.telia.se/2140",
          target: "_blank",
        },
      },
      {
        key: "nummerserier",
        enabled: false,
        enabledInTeliaAdmin: false,
        title: "Nummerserier",
        title_en: "Number series",
        description: "Välj hur du vill att dina nummerserier skall visas hos nummerupplysningen.",
        description_en: "Choose how your number series should be displayed.",
        icon: "job-search",
        pui: "READ_FIXED",
        link: {
          url:
            "/foretag/mybusiness/:scopeId/bestall/produkter-tjanster/fast-telefoni/abonnemang/bestallning/num-final",
          target: "",
        },
      },
      {
        key: "operator-connect",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Telia | Operator Connect till Microsoft Teams",
        title_en: "Telia | Operator Connect for Microsoft Teams",
        description:
          "Med Teams Operator Connect integrerar Telia all telefoni – fast och mobilt direkt in i Microsoft Teams.",
        description_en:
          "With Teams Operator Connect, all telephony – fixed and mobile - is seamlessly integrated by Telia to Microsoft Teams.",
        icon: "conversation",
        pui: "ORDER_OPERATOR_CONNECT",
        link: {
          url: "https://casey-ext.han.telia.se/3560",
          target: "_blank",
        },
      },
    ],
  },
  {
    key: "datakom",
    title: "Datakom",
    title_en: "Datacom",
    icon: "internet",
    subCategories: [
      {
        key: "datanet",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "DataNet",
        title_en: "DataNet",
        description: "Beställ DataNet.",
        description_en: "Order DataNet.",
        icon: "dsl-hub",
        pui: "ORDER_DATANET",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/datanet/oc",
          target: "",
        },
      },
      {
        key: "prolane",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Bredband Pro",
        title_en: "Broadband Pro",
        description: "Beställ Bredband Pro.",
        description_en: "Order Broadband Pro.",
        icon: "dsl-hub",
        pui: "ORDER_PROLANE",
        link: {
          url: "//casey-ext.han.telia.se/3873",
          target: "_blank",
        },
      },
    ],
  },
  {
    key: "m365",
    title: "Microsoft 365",
    title_en: "Microsoft 365",
    icon: "grid-view",
    subCategories: [
      {
        key: "m365",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Microsoft 365",
        title_en: "Microsoft 365",
        description: "Skicka Microsoft 365 beställning.",
        description_en: "Place Microsoft 365 order.",
        icon: "grid-view",
        pui: "",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/m365",
          target: "",
        },
      },
    ],
  },
  {
    key: "proptech",
    title: "Fastighetsägare",
    title_en: "Property technology",
    icon: "premises",
    subCategories: [
      {
        key: "proptech-hardware",
        enabled: true,
        enabledInTeliaAdmin: true,
        title: "Beställ hårdvara",
        title_en: "Order hardware",
        description:
          "Beställ extra hårdvara för TV, internet m.m till en Telia-uppkopplad fastighet.",
        description_en:
          "Order extra hardware for TV, internet etc. for a Telia connected property.",
        icon: "router",
        pui: "PROPTECH",
        link: {
          url: "/foretag/mybusiness/:scopeId/bestall/fastighetsagare/hardvara",
          target: "",
        },
      },
    ],
  },
];
