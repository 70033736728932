<template>
  <div class="container">
    <telia-row>
      <telia-col width="12">
        <telia-skeleton class="skeleton" />
      </telia-col>
    </telia-row>
    <telia-row>
      <telia-col width="4">
        <telia-skeleton class="skeleton skeleton--category" />
      </telia-col>
      <telia-col width="4">
        <telia-skeleton class="skeleton skeleton--category" />
      </telia-col>
      <telia-col width="4">
        <telia-skeleton class="skeleton skeleton--category" />
      </telia-col>
    </telia-row>
  </div>
</template>

<script>
export default {
  name: "loading-skeleton",
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.container {
  margin-top: $telia-spacing-48;
}

.skeleton {
  height: 9rem;
  width: 100%;
  margin-bottom: 1.6rem;

  &--category {
    height: 12rem;
  }
}
</style>
