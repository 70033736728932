<template>
  <div>
    <telia-notification :status="variant" heading-tag="h4">
      <span slot="heading">
        <telia-visually-hidden>{{ a11yMessage }}</telia-visually-hidden>
        <strong>{{ heading }}</strong>
      </span>
      <span slot="content">
        <telia-p>{{ message }}</telia-p>
      </span>
    </telia-notification>
  </div>
</template>

<script>
export default {
  name: "page-alert",
  props: {
    variant: {
      type: String,
      default: "warning",
    },
    heading: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
    a11yMessage: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";
</style>
