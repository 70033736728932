<template>
  <div>
    <b2b-basket-wrapper
      v-if="showBasket"
      t-id="b2b-order-page_basket-wrapper"
      :tscid="selectedOrganisation"
      :is-open="basketIsOpen"
      @update-drawer-state="$event = handleBasketDrawerState($event.detail[0])"
      @get-basket-error="handleBasketError"
    />
    <b2b-layout
      v-if="!loadingStates.scope"
      :heading="t('ORDER_PRODUCTS_AND_SERVICES')"
      :page-title="t('ORDER_PRODUCTS_AND_SERVICES')"
      :show-organisation-selector="true"
      :selected-organisation="selectedOrganisation"
      @organisationSelected="changeOrganisation($event.detail)"
    >
      <telia-grid t-id="b2b-order-page" class="order-page">
        <!-- Page alert -->
        <telia-row v-show="pageAlert.display" class="page-alert-container">
          <telia-col width="12">
            <page-alert
              :heading="t('SOMETHING_WENT_WRONG')"
              :message="pageAlert.message"
              :a11y-message="pageAlert.message"
              t-id="page-alert"
              variant="warning"
              class="page-alert-wrapper"
            />
          </telia-col>
        </telia-row>

        <!-- Loading indicator -->
        <loading-skeleton v-show="isLoading"></loading-skeleton>

        <!-- Product categories -->
        <telia-row
          v-if="!isLoading && userAccessibleProductCategoriesHasData"
          :key="selectedOrganisation"
          class="categories-container"
        >
          <telia-col width="12">
            <b2x-icon-tab-bar t-id="tab-bar" :notifications="tabsWithNotifications">
              <telia-tab-content
                v-for="tab in tabs"
                :key="tab.key"
                :t-id="`tab--${tab.key}`"
                :tab-id="tab.key"
                :name="tab.title"
                :icon="tab.icon"
              >
                <div class="categories-wrapper">
                  <product-card-link
                    v-for="category in tab.categories"
                    :badge-text="
                      category.key === 'formaner' && amountOfBenefitsRemaining > 0
                        ? t('AVAILABLE_BENEFITS', { benefits: amountOfBenefitsRemaining })
                        : ''
                    "
                    :key="category.key"
                    :t-id="`product-card-link--${category.key}`"
                    :product="category"
                    class="category"
                  />
                </div>
              </telia-tab-content>
            </b2x-icon-tab-bar>
          </telia-col>
        </telia-row>

        <!-- Product categories info -->
        <telia-row
          v-else-if="!isLoading && !userAccessibleProductCategoriesHasData"
          class="page-alert-container"
        >
          <telia-col width="12">
            <page-alert
              :heading="t('SOMETHING_WENT_WRONG')"
              :message="t('INFO_NO_PRODUCTS_OR_SERVICES')"
              :a11y-message="t('INFO_NO_PRODUCTS_OR_SERVICES')"
              t-id="empty-category-alert"
              variant="information"
              class="page-alert-wrapper"
            />
          </telia-col>
        </telia-row>
      </telia-grid>
    </b2b-layout>
  </div>
</template>

<script>
import LoadingSkeleton from "./components/loading-skeleton";
import PageAlert from "./components/page-alert";
import ProductCardLink from "./components/product-card-link";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { corpSegment } from "@telia/b2x-rest-client";
import {
  corpAgreements,
  corpScopeInformation,
  corpOrderService,
  corpGoodiesService,
  corpInstalledbaseListPagination,
  corpProptechService,
} from "@telia/b2b-rest-client";
import { currentLocale } from "@telia/b2b-i18n";
import { addNewToast, getAllMessages } from "@telia/b2b-message-service";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { translateSetup, translateMixin } from "./locale";
import { productCategories as productCategoriesData } from "./productCategories.js";
import { sdwTestUrlsEnabled } from "./featureToggles.js";
import { useFlag } from "@unleash/proxy-client-vue";
import { defineComponent } from "vue";

const MAXIMUM_ALLOWED_BENEFITS = 10;

export default defineComponent({
  name: "b2b-order-page",

  mixins: [translateMixin],

  components: {
    LoadingSkeleton,
    PageAlert,
    ProductCardLink,
  },

  setup() {
    // TODO: Feature flags. Cleanup when appropriate.
    const orderEmnFeatureEnabled = useFlag("b2b-order-emn");
    const benefitsNotificationEnabled = useFlag("b2b-benefits-notification");
    const orderPropTechHardwareEnabled = useFlag("b2b-order-proptech-hardware");
    return {
      orderEmnFeatureEnabled,
      benefitsNotificationEnabled,
      orderPropTechHardwareEnabled,
    };
  },

  data() {
    return {
      basketIsOpen: false,
      loadingStates: {
        scope: false,
        init: false,
        changeOrg: false,
      },
      isTeliaAdmin: false,
      organizations: null,
      selectedOrganisation: null,
      mobileAgreements: null,
      scopeId: "",
      currentUserPermissions: {},
      currentUserServiceWebs: {},
      currentLanguage: currentLocale(),
      sdwLoginRequired: false,
      hasSdwOrderPermission: false,
      hasDataNetOrderPermission: false,
      hasBenefitsPermission: false,
      businessSegment: "",
      nfaStatus: [],
      pageAlert: {
        display: false,
        message: "",
      },
      eligibleSubscriptions: [],
      loadingUserData: true,
      hasTppSwitch: false,
      hasPropTechAccess: false,
    };
  },

  computed: {
    isLoading() {
      return Object.values(this.loadingStates).some((val) => !!val);
    },
    userHasSdwAccess() {
      return (this.currentUserServiceWebs?.serviceWebs ?? []).indexOf("SDW") !== -1;
    },

    userHasTradeAccess() {
      return this.currentUserServiceWebs?.serviceWebs?.indexOf("TRADE") !== -1 || false;
    },

    hasEmnPermission() {
      return (
        this.orderEmnFeatureEnabled &&
        this.mobileAgreements?.some((agreement) => agreement.agreementType === "EMR")
      );
    },

    hasPropTechHardwarePermission() {
      return this.orderPropTechHardwareEnabled && this.hasPropTechAccess;
    },

    tabsWithNotifications() {
      const tabsWithNotifications = [];
      if (this.amountOfBenefitsRemaining > 0) {
        tabsWithNotifications.push("formaner");
      }
      return tabsWithNotifications;
    },

    tabs() {
      return this.userAccessibleProductCategories.map((category) => ({
        key: category.key,
        title: category.title,
        icon: category.icon,
        categories: category.subCategories,
      }));
    },

    productCategories() {
      const isCurrentLangEn = this.currentLanguage === "EN_US";

      const clone = deepCopy(productCategoriesData);

      return clone.map((category) => {
        category.title = isCurrentLangEn ? category.title_en : category.title;

        if (category.subCategories) {
          category.subCategories
            .filter((subCategory) => this.isApplicableForCustomer(subCategory))
            .forEach((subCategory) => {
              subCategory.title = isCurrentLangEn ? subCategory.title_en : subCategory.title;
              subCategory.description = isCurrentLangEn
                ? subCategory.description_en
                : subCategory.description;

              if (subCategory.link?.url) {
                subCategory.link.url = this.parseLinkForSubCategory(subCategory);
              }

              subCategory.hasAccess = this.hasAccess(subCategory);
            });
        }

        return category;
      });
    },

    userAccessibleProductCategories() {
      return this.productCategories
        .filter((category) => this.hasEnabledAndAccessibleSubCategories(category.subCategories))
        .map((category) => {
          category.subCategories = category.subCategories.filter(
            (subCategory) =>
              subCategory.enabled &&
              subCategory.hasAccess &&
              (!this.isTeliaAdmin || (this.isTeliaAdmin && subCategory.enabledInTeliaAdmin))
          );
          return category;
        });
    },

    userAccessibleProductCategoriesHasData() {
      return (
        this.productCategories &&
        Array.isArray(this.productCategories) &&
        this.productCategories.length > 0
      );
    },

    hasNewFrameAgreement() {
      return this.userAccessibleNewFrameAgreements.length > 0;
    },

    userAccessibleNewFrameAgreements() {
      if (!this.nfaStatus?.agreements) {
        return [];
      }
      const validNfAgreements = this.nfaStatus?.agreements?.filter(
        (agreement) =>
          agreement.tscid === this.selectedOrganisation &&
          !!agreement.nfAgreementNumber &&
          agreement.agreementType !== "EMR"
      );
      return validNfAgreements;
    },

    hasProLanePermission() {
      return this.businessSegment === "LARGE";
    },

    hasOperatorConnectPermission() {
      return this.businessSegment === "LARGE" || this.businessSegment === "FUNCTION";
    },

    amountOfSelectedBenefits() {
      return this.eligibleSubscriptions.filter((s) => s.currentHoldings.length).length;
    },

    amountOfBenefitsRemaining() {
      return this.amountOfBenefitsAllowed - this.amountOfSelectedBenefits;
    },

    amountOfBenefitsAllowed() {
      return this.eligibleSubscriptions.length > MAXIMUM_ALLOWED_BENEFITS - 1
        ? MAXIMUM_ALLOWED_BENEFITS
        : this.eligibleSubscriptions.length;
    },

    showBasket() {
      return (
        !this.isLoading &&
        this.selectedOrganisation &&
        this.businessSegment !== "FUNCTION" &&
        !(this.hasSdwOrderPermission || this.userHasSdwAccess)
      );
    },
  },

  async created() {
    await this.init();

    this.trackOpenPageEvent();

    if (this.userHasSdwAccess) {
      window.addEventListener("focus", this.fetchSdwPermissions, false);
    }
  },

  methods: {
    async init() {
      this.loadingStates.init = true;

      await translateSetup();
      this.loadingStates.scope = true;
      await this.fetchUserData();
      await this.getBusinessSegment();
      this.loadingStates.scope = false;
      // this.getTppSwitches();
      await this.fetchDataNetPermissions();

      if (this.userHasSdwAccess) {
        await this.fetchSdwPermissions();
      }
      this.loadingStates.init = false;
    },

    async changeOrganisation(org) {
      this.loadingStates.changeOrg = true;
      this.selectedOrganisation = org;
      this.fetchPropTechAccess();
      await Promise.all([this.fetchMobileAgreement(), this.fetchNfaInformation()]);
      await this.getBenefitsPermissions();
      if (this.hasBenefitsPermission && this.benefitsNotificationEnabled) {
        this.getBenefits();
      }
      this.loadingStates.changeOrg = false;
    },

    trackOpenPageEvent() {
      analytics.trackEvent(analytics.category.BESTALL, analytics.action.OPEN, "Produkter listvyn.");
    },

    async fetchUserData() {
      try {
        this.scopeId = await getScopeIdOrThrow();
        this.isTeliaAdmin = await isTeliaAdmin();
        const scopeDetails = await corpScopeInformation.ScopeControllerService.getScopeDetails(
          this.scopeId
        );
        this.currentUserPermissions = scopeDetails.scope.actions.available;
        this.currentUserServiceWebs = await corpScopeInformation.ScopeControllerService.getServiceWebs(
          this.scopeId
        );
      } catch (e) {
        this.showPageAlert(this.t("ERROR_COULD_NOT_FETCH_DATA"));
      }
    },

    async fetchNfaInformation() {
      try {
        this.nfaStatus = await corpAgreements.NfAgreementsControllerService.getNfAgreementsOnScope(
          this.scopeId,
          this.selectedOrganisation
        );
      } catch (e) {
        this.nfaStatus = [];
      }
    },

    async fetchSdwPermissions() {
      try {
        const permissionData = await corpOrderService.SdwOrderPermissionsControllerService.getSdwUserPermissions(
          this.scopeId
        );
        this.sdwLoginRequired = permissionData.outcome === "SDW_LOGIN_REQUIRED";
        this.hasSdwOrderPermission =
          permissionData.outcome === "OK" &&
          permissionData.permissionList.indexOf("ORDER_NEW_SUBSCRIPTION") !== -1;
      } catch (e) {
        this.sdwLoginRequired = false;
        this.hasSdwOrderPermission = false;
      }
    },

    async fetchDataNetPermissions() {
      try {
        const permissionData = await corpOrderService.DataComOrderPermissionsControllerService.getDataComUserPermissions(
          this.scopeId
        );
        this.hasDataNetOrderPermission = permissionData.permissionList.indexOf("DATANET") !== -1;
      } catch (e) {
        this.hasDataNetOrderPermission = false;
      }
    },

    async getBusinessSegment() {
      try {
        const permissionData = await corpSegment.PublicService.getSegment1(this.scopeId);
        this.businessSegment = permissionData.results.mybusinessSegment;
      } catch {
        this.showPageAlert(this.t("ERROR_COULD_NOT_FETCH_DATA"));
      }
    },

    isBenefitsEligibleBusinessSegment() {
      return this.businessSegment === "SME" || this.businessSegment === "SOHO";
    },

    async fetchMobileAgreement() {
      this.mobileAgreements = null;
      try {
        const agreementResponse = await corpAgreements.MobileAgreementsControllerService.getMobileAgreementsNewAuth(
          this.scopeId,
          { agreementLevels: ["RAM", "UNDER"], tscId: this.selectedOrganisation }
        );
        this.mobileAgreements = agreementResponse.results;
      } catch {
        this.mobileAgreements = null;
      }
    },

    async getBenefitsPermissions() {
      if (!this.mobileAgreements) return;
      this.hasBenefitsPermission = false;

      if (this.isSRACustomer()) {
        if (this.isBenefitsEligibleBusinessSegment()) {
          if (this.mobileAgreements.length === 0) {
            this.hasBenefitsPermission = true;
            return;
          }

          if (this.mobileAgreements.some((agreement) => agreement.agreementType === "SME")) {
            this.hasBenefitsPermission = true;
          }
        }
      }
    },

    async fetchPropTechAccess() {
      try {
        const response = await corpProptechService.HardwareControllerService.hasAccess(
          this.scopeId,
          this.selectedOrganisation
        );
        this.hasPropTechAccess = response.hasAccess;
      } catch (e) {
        this.hasPropTechAccess = false;
      }
    },

    isSRACustomer() {
      return this.scopeId.startsWith("8");
    },

    isApplicableForCustomer(subCategory) {
      switch (subCategory.key) {
        // Show MCO order flow link if the customer has NFA
        case "mobiltelefoner-mco":
          return this.hasNewFrameAgreement;
        // Hide Trade link if the customer has NFA
        case "mobiltelefoner":
          return !this.hasNewFrameAgreement;
        // Show MCO order flow link if the customer has NFA
        case "mobilabonnemang-mco":
          return this.hasNewFrameAgreement;
        // Hide "old" subscription sales flow link if the customer has NFA
        case "mobilabonnemang":
          return !this.hasNewFrameAgreement;
        case "emn-subscriptions":
          return this.hasEmnPermission;
        default:
          return true;
      }
    },
    parseLinkForSubCategory(subCategory) {
      if (!subCategory?.link?.url) {
        return;
      }

      let link = subCategory.link.url;

      link = link.replace(":scopeId", this.scopeId);

      if (
        subCategory.key === "mobiltelefoner-mco" ||
        subCategory.key === "mobilabonnemang-mco" ||
        subCategory.key === "bredband-otp" ||
        subCategory.key === "emn-subscriptions"
      ) {
        link = link.replace(":tscid", this.selectedOrganisation);
      }

      if (subCategory.key === "avropa-avtalstjanster-ej-inloggad") {
        if (sdwTestUrlsEnabled()) {
          link = "https://servicedeskweb-at1.teliasonera.net/SSO/MyBusinessLogin.aspx";
        }
      }

      if (subCategory.key === "avropa-avtalstjanster") {
        if (sdwTestUrlsEnabled()) {
          link =
            "https://servicedeskweb-at1.teliasonera.net/SSO/MyBusinessLogin.aspx?ReturnUrl=/Order/NewMultiOrder.aspx";
        }
      }

      return link;
    },

    hasAccess(subCategory) {
      if (!subCategory.pui) {
        return true;
      }
      switch (subCategory.pui) {
        case "SDW":
          return this.hasSdwOrderPermission && !this.sdwLoginRequired;
        case "SDW_NO_SSO":
          return !this.hasSdwOrderPermission && this.sdwLoginRequired;
        case "TRADE":
          return this.userHasTradeAccess;
        case "ORDER_DATANET":
          return this.hasDataNetOrderPermission;
        case "ORDER_BENEFITS":
          return this.hasBenefitsPermission;
        case "ORDER_PROLANE":
          return this.hasProLanePermission;
        case "ORDER_OPERATOR_CONNECT":
          return this.hasOperatorConnectPermission;
        case "PROPTECH":
          return this.hasPropTechHardwarePermission;
        default:
          return this.hasAccessToPui(subCategory.pui);
      }
    },

    hasAccessToPui(pui) {
      return this.currentUserPermissions?.puis?.indexOf(pui) !== -1 || false;
    },

    hasEnabledAndAccessibleSubCategories(subCategories) {
      return (
        subCategories?.some(
          (subCategory) =>
            subCategory.enabled &&
            subCategory.hasAccess &&
            (!this.isTeliaAdmin || (this.isTeliaAdmin && subCategory.enabledInTeliaAdmin))
        ) || false
      );
    },

    showPageAlert(message) {
      this.pageAlert.display = true;
      this.pageAlert.message = message;
    },

    async getBenefits() {
      try {
        corpGoodiesService.GoodiesControllerService.listGoodiesPerSubscriptionUsingGet(
          this.scopeId,
          this.selectedOrganisation
        ).then((resp) => {
          this.eligibleSubscriptions = resp.eligible ?? [];
        });
      } catch {
        //will not show benefit link
      }
    },
    // Needs analysis
    // async getTppSwitches() {
    //   const sort = { column: "subscriptionIdentifier", direction: "ASC" };
    //   try {
    //     const response = await corpInstalledbaseListPagination.ListPaginationControllerImplService.getPaginatedSubscriptions(
    //       this.scopeId,
    //       {
    //         tscidsFilter: [],
    //         mainCategory: "SWITCH",
    //         subCategories: ["SWITCHBOARD_UCS"],
    //         sort,
    //         columnFilter: {},
    //         agreementsFilter: [],
    //       }
    //     );
    //     const filteredResponse = response.subscriptions?.filter((sub) =>
    //       sub.offering?.includes("Touchpoint Plus")
    //     );
    //     this.hasTppSwitch = filteredResponse.length > 0;
    //   } catch {
    //     //will not show tpp-aftermarket
    //   }
    // },
    handleBasketDrawerState(open) {
      this.basketIsOpen = open;
    },
    handleBasketError(event) {
      const [hasError] = event.detail;
      if (hasError && !getAllMessages().some((toast) => toast.id === "get-basket-error")) {
        addNewToast(
          "get-basket-error",
          "warning",
          this.t("SOMETHING_WENT_WRONG"),
          this.t("GET_BASKET_ERROR")
        );
      }
    },
  },
});
function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/breakpoints/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.order-page {
  min-height: calc(100vh - 405px);
  margin-bottom: $telia-spacing-24;
}

.breadcrumb-container {
  margin-top: $telia-spacing-16;
}

.page-heading-container {
  margin-top: $telia-spacing-32;
}

.page-alert-container {
  margin-top: $telia-spacing-24;
}

.page-alert-wrapper {
  max-width: 58rem;
}

.categories-container {
  margin-top: $telia-spacing-24;
}

.categories-wrapper {
  display: flex;
  flex-flow: row wrap;

  .category {
    margin: 0 $telia-spacing-24 $telia-spacing-24 0;
  }
}
</style>
