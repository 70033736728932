<template>
  <a
    :t-id="`product-card-link-${product.key}`"
    class="product-card-link"
    :href="product.link.url"
    :target="product.link.target"
    @click="trackClickEvent"
  >
    <div v-if="badgeText" class="product-card-link__badge">
      <telia-badge variant="information">{{ badgeText }}</telia-badge>
    </div>
    <div class="product-card-link__heading-container">
      <telia-heading
        tag="h2"
        variant="title-100"
        class="product-card-link__heading-container--heading"
      >
        {{ product.title }}
      </telia-heading>
      <telia-icon
        v-if="product.link.target === '_blank'"
        name="external"
        size="sm"
        class="product-card-link__heading-container--heading-icon"
      ></telia-icon>
    </div>
    <div class="product-card-link__container">
      <telia-icon
        :name="product.icon"
        size="lg"
        class="product-card-link__container--icon"
      ></telia-icon>
      <telia-p class="product-card-link__container--paragraph" disable-hyphenation>
        {{ product.description }}
      </telia-p>
    </div>
  </a>
</template>

<script>
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { logInfo } from "../helpers/dataLogging";

export default {
  name: "product-card-link",

  props: {
    product: {
      type: Object,
      required: true,
    },
    badgeText: {
      type: String,
      required: false,
    },
  },

  methods: {
    trackClickEvent() {
      analytics.trackEvent(
        analytics.category.BESTALL,
        analytics.action.CLICK,
        "Vald produkt: " + this.product.title
      );
      if (this.product.key === "IoT-subscriptions") {
        logInfo("IoT-sub entry point: Products and services");
      }
      if (this.badgeText) {
        logInfo("b2b-benefits-notification - B - click");
      } else if (this.product.key === "formaner") {
        logInfo("b2b-benefits-notification - A - click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/borders/mixins.scss";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.product-card-link {
  display: flex;
  flex-direction: column;
  justify-content: left;
  @include telia-border("gray-200", "xs", "all");
  padding: $telia-spacing-20;
  width: 40rem;
  min-height: 8rem;
  text-decoration: none;
  transition: border-color 0.1s ease, color 0.1s ease;
  position: relative;

  &__badge {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__heading-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: $telia-spacing-8;

    &--heading {
      color: $telia-purple-500;
    }

    &--heading-icon {
      color: $telia-purple-500;
      min-width: 1.6rem;
      margin-left: $telia-spacing-12;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: normal;

    &--icon {
      color: $telia-black;
      min-width: 4.6rem;
      margin-right: $telia-spacing-16;
    }

    &--paragraph {
      flex-basis: content;
      color: $telia-black;
    }
  }
}

.product-card-link:hover {
  border-color: $telia-purple-500;
  transition: border-color 0.1s ease, color 0.1s ease;
}
</style>
